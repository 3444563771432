import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of as observableOf } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartRequestsRepoService {

  constructor(private _httpClient: HttpClient) { }

  getPartRequests(params): Observable<any> {
    let query = '';
    if(params && typeof params === 'object') {
      query = `?params=${encodeURIComponent(JSON.stringify(params))}`;
    }

    const href = environment.apiUrl + 'part-requests';
    const requestUrl = `${href}${query}`;

    return this._httpClient.get<any>(requestUrl);
  }

  getAutocomplete(data: object): Observable<any> {
    const href = environment.apiUrl + 'part-requests/autocomplete';
    const requestUrl = `${href}?params=${encodeURIComponent(JSON.stringify(data))}`;

    return this._httpClient.get<any>(requestUrl);
  }

  changeStatusPartRequest(data: object): Observable<any> {
    const href = environment.apiUrl + 'part-requests/change_status';
    const requestUrl = `${href}?params=${encodeURIComponent(JSON.stringify(data))}`;

    return this._httpClient.get<any>(requestUrl);
  }

  changeShowPartRequest(data: object): Observable<any> {
    const href = environment.apiUrl + 'part-requests/change_show';
    const requestUrl = `${href}?params=${encodeURIComponent(JSON.stringify(data))}`;

    return this._httpClient.get<any>(requestUrl);
  }

  createEditPartRequest(formValues, isEdit): Observable<any> {
    if(!formValues || typeof formValues !== 'object') {
      return observableOf(false);
    }

    const href = isEdit ? environment.apiUrl + 'part-requests/edit' : environment.apiUrl + 'part-requests/create';
    const requestUrl = `${href}`;
    let content = JSON.stringify(formValues);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._httpClient.post<any>(requestUrl, content, options);
  }

  getAjaxUrl(): string {
    return environment.apiUrl + 'part-requests';
  }
}
