<mat-dialog-header>
    <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
</mat-dialog-header>
<mat-dialog-content>
    <!-- <h3>How it works</h3> -->

    <p>
        Can't find something in the database? You can
        post requests for test data anonymous or send
        your question to us only. Someone else may be
        interested in the same data. In that case, we
        can facilitate cost sharing of radiation testing
        for these common interest data. The entire
        process is done anonymously to ensure
        confidentiality. Common interest parts are
        identified with color background in the table.
        <br>
        <br>
        Have more questions? Send us an <a mat-dialog-close class="text-link" routerLink="/inquiry">inquiry</a>!
    </p>
</mat-dialog-content>