import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/service/shared.service';

import { PartRequestsRepoService } from 'src/app/repositories/part-requests-repo/part-requests-repo.service';
import { SecurityRepoService } from 'src/app/repositories/security-repo/security-repo.service';

@Component({
  selector: 'app-part-requests-confirmation-dialog',
  templateUrl: './part-requests-confirmation-dialog.component.html',
  styleUrls: ['./part-requests-confirmation-dialog.component.scss']
})
export class PartRequestsConfirmationDialogComponent implements OnInit {
  isLoading: boolean = false;

  private _errors = [];
  get errors() { return this._errors };

  canEdit = false;

  isAdmin = false;
  
  partRequestId = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _dialogData,
    private _securityRepoService: SecurityRepoService,
    private _dialogRef: MatDialogRef<PartRequestsConfirmationDialogComponent>,
    private _partRequestsRepo: PartRequestsRepoService,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    let defaultValues = {
      partNumber: '',
      manufacturer: '',
      function: '',
      dataType: [],
      notes: ''
    };
    

    if(this._dialogData && this._dialogData.defaultValues) {
      if(this._dialogData.defaultValues.partRequestId !== 0) {
        this.partRequestId = this._dialogData.defaultValues.partRequestId;
      }
    }
  }

  onSubmitPartRequest(isEdit = false) {
    this._errors = [];
    this.isLoading = true;

    let value = this._dialogData.defaultValues;
    value.id = this.partRequestId;
    
    this._partRequestsRepo.createEditPartRequest(value, isEdit)
    .pipe(catchError((err) => {
      this.isLoading = false;
      let ajaxResult = {
        errors: {
          detail: 'Failed to process part request!'
        }
      };

      return observableOf(ajaxResult);
    }))
    .subscribe(responseData => {
      this.isLoading = false;
      let hasResult = responseData && typeof responseData === 'object';
      if(hasResult && responseData['isSuccess'] === true) {
        this._dialogRef.close(true);
        this._snackBar.open('Your part request is submitted!', 'ok', { duration: 7000 });
        this.sharedService.emitEvent('request_added');

        return;
      }

      let hasErrors = hasResult && responseData['errors'] && typeof responseData['errors'] === 'object';
      let errors = hasErrors ? responseData['errors'] : {};
      let errorMessage = 'Failed to process part request!';
      if(typeof errors['detail'] === 'string') {
        errorMessage = errors['detail'];
      }

      let hasValidationErrors = typeof errors['validationErrors'] === 'object' && errors['validationErrors'];
      if(hasValidationErrors && Array.isArray(errors['validationErrors']['violations'])) {
        for(let violation of errors['validationErrors']['violations']) {
          let title = 'Unknown error';
          if(violation['title'] && typeof violation['title'] === 'string') {
            title = violation['title'];
          }
        }
      }

      this._errors.push(errorMessage);
    });
  }

}
