<div class="part-request-form-dialog-content">
  <div class="loading-shade" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <div mat-dialog-title align="center">
    Show same interest?
  </div>
  <div mat-dialog-content>
    <div *ngIf="errors" class="error-message">
      <ng-container *ngFor="let error of errors">
        {{ error }}<br />
      </ng-container>
    </div>
  </div>
  <mat-dialog-actions class="part-request-confirmation-dialog-action-buttons">
    <button mat-button (click)="onSubmitPartRequest()">
      Confirm
    </button>
    <button mat-button [mat-dialog-close]="false">Cancel</button>
  </mat-dialog-actions>
</div>
