import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private eventSubject = new Subject<any>();

  // Observable to be used for subscribing to the event
  eventObservable$ = this.eventSubject.asObservable();

  // Method to emit the event
  emitEvent(data: any) {
    this.eventSubject.next(data);
  }
}